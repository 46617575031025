@charset "UTF-8";
:root {
  --global-form-color-text: var(--global-color-text);
  --global-form-color-text-contract: var(--global-color-gray-200);
  --global-form-color-placeholder: var(--global-color-gray-400);
  --global-form-color-warn: var(--global-color-warn);
  --global-form-color-warn-contract: var(--global-color-warn-contract);
  --global-form-color-active: var(--global-color-warn);
  --global-form-color-active-contract: var(--global-color-warn-contract);
  --global-form-typofgraphy-fontSize-xs: 0.625rem;
  --global-form-typofgraphy-fontSize-sm: 0.75rem;
  --global-form-typofgraphy-fontSize: var(--global-typography-fontSize-default);
  --global-form-typofgraphy-lineHeight: 1.5;
  --global-form-typofgraphy-lineHeight-lg: 1.8;
  --global-form-typofgraphy-lineHeight-xl: 2;
  --global-form-container-inner-gutter: 16px;
  --global-form-element-min-height: 42px;
  --global-form-element-input-gap-x: 16px;
  --global-form-element-input-gap-x-lg: 12px;
  --global-form-element-input-gap-x-xl: 16px;
  --global-form-element-input-gap-x-2xl: 20px;
  --global-form-element-input-gap-x-3xl: 24px;
  --global-form-element-input-gap-y: 16px;
  --global-form-element-input-gap-y-lg: 12px;
  --global-form-element-input-gap-y-xl: 16px;
  --global-form-element-input-gap-y-2xl: 20px;
  --global-form-element-input-gap-y-3xl: 24px;
  --global-form-element-input-gap-y-4xl: 28px;
  --global-form-element-input-gap-y-5xl: 32px;
  --global-form-state-readonly-opacity: .5;
  --global-form-state-disabled-opacity: .5;
}

/* スライダー非活性時 */
.swiper:not(.swiper-initialized) {
  padding: 0;
}
.swiper:not(.swiper-initialized) ~ .swiper-button-prev, .swiper:not(.swiper-initialized) ~ .swiper-button-next {
  display: none;
}
.swiper:not(.swiper-initialized) .swiper-wrapper {
  display: block;
}
.swiper:not(.swiper-initialized) .swiper-pagination-bullet {
  display: none;
}

.js-slider .swiper:not(.swiper-initialized) {
  overflow: initial;
}
.js-slider .swiper:not(.swiper-initialized) .swiper-wrapper {
  display: flex;
}
.js-slider .swiper:not(.swiper-initialized) .swiper-slide {
  height: auto;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 15px;
}

.swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.swiper-button-next,
.swiper-button-prev {
  width: 36px;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  content: "";
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/top/icon_arrow_l.svg);
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
  content: "";
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/top/icon_arrow_l.svg);
  transform: rotate(180deg);
}

@media (max-width: 768px) {
  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
  }

  .js-slider {
    overflow: hidden;
  }
  .js-slider .slide {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
[data-accordion] {
  --cssui-animation-timing: 0.3s;
  --cssui-animation-rotate: rotate(-180deg);
  --accordion-panel-padding-y: 1em;
}

[data-accordion-item] {
  width: 100%;
}

[data-accordion-item] > input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

[data-accordion-item] > label {
  cursor: pointer;
}

/* Accordion Item's Label Focus State */
[data-accordion-item] > input:focus + label {
  outline: 2px solid #101010;
  outline: 2px solid highlight;
  outline: 2px solid -webkit-focus-ring-color;
}

/* Hide focus ring if focus comes from a pointer device. */
[data-accordion-item] > input:focus:not(:focus-visible) + label {
  outline: none;
}

[data-accordion-item] > label > svg {
  transition: all var(--cssui-animation-timing) ease-out;
}

[data-accordion-panel] {
  max-height: 0;
  transition: padding var(--cssui-animation-timing) ease;
  visibility: hidden;
  padding-block: 0;
}

[data-accordion-item] > input:checked + label > svg {
  transform: rotate(-180deg);
}

[data-accordion-item] > input:checked ~ [data-accordion-panel] {
  max-height: 100vh;
  visibility: visible;
  padding-block: var(--accordion-panel-padding-y);
}

.no-js img.lazyload {
  display: none;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.4s;
}

/**
 * どのような構造で配置されていても、左右画面いっぱいに広がるようにする
 */
/**
  全てのフォームコンポーネントの共通機能
  inputに直接付与すること
 */
/**
  input,textarea,select
 */
/**
GMJのフォームの補助テキストは、基本的に「下揃え」
 */
.sct-cv {
  padding: 22px;
  padding-top: 48px;
  padding-bottom: 56px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../img/common/bg_conversion_pc.jpg);
}
@media (max-width: 1199px) {
  .sct-cv {
    background-image: url(../img/common/bg_conversion.jpg);
  }
}
.sct-cv .inr {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-shrink: 0;
  max-width: 920px;
  margin: auto;
  position: relative;
}
.sct-cv .div {
  color: var(--color-white, #ffffff);
  text-align: center;
  position: relative;
  align-self: stretch;
}
.sct-cv .div-span {
  color: var(--color-white, #ffffff);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .sct-cv .div-span {
    font-size: 20px;
  }
}
.sct-cv .div-span2 {
  color: var(--color-white, #ffffff);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 24px;
  line-height: 160%;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .sct-cv .div-span2 {
    font-size: 20px;
  }
}
.sct-cv .div-span3 {
  color: var(--color-white, #ffffff);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .sct-cv .div-span3 {
    font-size: 20px;
  }
}
.sct-cv .div-span4 {
  color: var(--color-white, #ffffff);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 24px;
  line-height: 160%;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .sct-cv .div-span4 {
    font-size: 20px;
  }
}
.sct-cv .div-span5 {
  color: var(--color-white, #ffffff);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .sct-cv .div-span5 {
    font-size: 20px;
  }
}
.sct-cv .div-span6 {
  color: var(--color-white, #ffffff);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 24px;
  line-height: 160%;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .sct-cv .div-span6 {
    font-size: 20px;
  }
}
.sct-cv .div-span7 {
  color: var(--color-white, #ffffff);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 30px;
  line-height: 160%;
  font-weight: 600;
}
@media (max-width: 1199px) {
  .sct-cv .div-span7 {
    font-size: 20px;
  }
}
.sct-cv .btan-base {
  background: var(--color-keygold, #a59450);
  padding: 3px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  max-width: 480px;
  margin: auto;
  height: 80px;
  position: relative;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}
.sct-cv .btan-base:hover {
  opacity: 0.6;
}
.sct-cv .inr2 {
  border-style: solid;
  border-color: var(--color-keygoldlight, #eeebe0);
  border-width: 1px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
  padding: 0 20px;
}
.sct-cv .medal {
  background: var(--color-white, #ffffff);
  border-radius: 27px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 54px;
  height: 54px;
  position: relative;
}
.sct-cv .div2 {
  color: #222222;
  text-align: left;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 19px;
  line-height: 100%;
  font-weight: 600;
  position: relative;
}
.sct-cv .lavel {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  position: relative;
}
.sct-cv .div3 {
  color: var(--color-white, #ffffff);
  text-align: left;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 22px;
  line-height: 140%;
  font-weight: 600;
  position: relative;
}
.sct-cv .icon-arrow {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
  overflow: visible;
}

.sct-qa {
  position: relative;
  overflow: hidden;
}
.sct-qa .sct_inner {
  background: #fff;
  position: relative;
  padding: 64px 22px;
}
@media (min-width: 641px) {
  .sct-qa .sct_inner {
    padding: 64px 38px;
  }
}
@media (min-width: 1200px) {
  .sct-qa .sct_inner {
    padding: 110px 48px;
  }
}
.sct-qa .block {
  max-width: 920px;
  margin: auto;
  margin-top: 64px;
}
@media (max-width: 1199px) {
  .sct-qa .block {
    margin-top: 48px;
  }
}
.sct-qa .sec-description {
  color: var(--color-black, #1f1f1f);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 200%;
  /* 32px */
  text-align: left;
  margin-top: 32px;
}
@media (min-width: 1200px) {
  .sct-qa .sec-description {
    text-align: center;
  }
}
.sct-qa .block,
.sct-qa .block * {
  box-sizing: border-box;
}
.sct-qa .block {
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-qa .block {
    flex-direction: column;
    align-items: center;
  }
}
.sct-qa .li-card-faq {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  max-width: 440px;
  position: relative;
}
.sct-qa .img-faq-shimane {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.sct-qa .ogp-1 {
  flex-shrink: 0;
  width: 100%;
  max-width: 440px;
  position: relative;
  object-fit: cover;
}
.sct-qa .header-logo-2-x-1 {
  flex-shrink: 0;
  width: 131px;
  height: 24px;
  position: absolute;
  left: 8px;
  top: 9px;
  object-fit: cover;
}
.sct-qa .btan-base {
  background: var(--color-keyred, #d10000);
  padding: 2px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 260px;
  height: 54px;
  position: relative;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}
.sct-qa .btan-base:hover {
  opacity: 0.6;
}
.sct-qa .inr {
  border-style: solid;
  border-color: var(--color-keygoldlight, #eeebe0);
  border-width: 1px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  position: relative;
  overflow: hidden;
}
.sct-qa .lavel {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.sct-qa .div {
  color: var(--color-white, #ffffff);
  text-align: left;
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
  flex-direction: column;
}
.sct-qa .div .-sub {
  font-feature-settings: "palt" on;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 14.4px */
  letter-spacing: 0.48px;
}
.sct-qa .icon-arrow-blue-svg {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  position: relative;
  overflow: visible;
}
.sct-qa .img-faq-tottori {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
}
.sct-qa .kv-01-1 {
  flex-shrink: 0;
  width: 100%;
  max-width: 440px;
  position: relative;
  object-fit: cover;
}
.sct-qa .image-16 {
  flex-shrink: 0;
  width: 183px;
  height: 28px;
  position: absolute;
  left: 10px;
  top: 7px;
  object-fit: cover;
}
.sct-qa .icon-arrow-blue-svg2 {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  position: relative;
  overflow: visible;
}

.sct-about {
  position: relative;
  overflow: hidden;
}
.sct-about .sct_inner {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../img/top/about/bg_img_btm.jpg);
  position: relative;
  padding: 64px 22px;
  text-align: center;
}
@media (min-width: 641px) {
  .sct-about .sct_inner {
    padding: 64px 38px;
  }
}
@media (min-width: 1200px) {
  .sct-about .sct_inner {
    padding: 110px 48px;
    background-image: url(../img/top/about/bg_about_pc.jpg);
  }
}
.sct-about h2 {
  color: var(--color-white, #fff);
  text-align: center;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 48px */
}
@media (max-width: 1199px) {
  .sct-about h2 {
    font-size: 22px;
    line-height: 1.7;
  }
}
.sct-about .sub {
  display: inline-block;
  color: var(--color-key_gold, #a59450);
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  /* 27.2px */
  border: 1px solid var(--color-key_gold, #a59450);
  padding: 8px 16px;
  margin-top: 32px;
}
@media (max-width: 1199px) {
  .sct-about .sub {
    margin-top: 24px;
    display: block;
  }
}
.sct-about .block,
.sct-about .block * {
  box-sizing: border-box;
}
.sct-about .block {
  margin: auto;
  margin-top: 64px;
  max-width: 920px;
  display: flex;
  flex-direction: column;
  gap: 56px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-about .block {
    margin-top: 48px;
    gap: 40px;
  }
}
.sct-about .about {
  background: var(--color-white, #ffffff);
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}
.sct-about .inr {
  background: var(--color-white, #ffffff);
  border-style: solid;
  border-color: var(--color-keygold, #a59450);
  border-width: 1px;
  padding: 44px 40px 38px 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-about .inr {
    padding: 26px 20px;
    gap: 24px;
  }
}
.sct-about .img-list {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-about .img-list {
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    max-width: 284px;
    margin: auto;
  }
}
.sct-about .img-about-01 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  max-width: 480px;
  margin: auto;
}
.sct-about .img-about-02 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}
.sct-about .frame-395 {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sct-about .frame-393 {
  padding: 0px 40px 0px 40px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-about .frame-393 {
    padding: 0;
  }
}
.sct-about .u-i {
  color: var(--color-black, #1f1f1f);
  text-align: left;
  font-size: var(--textcard14px18-font-size, 14px);
  line-height: var(--textcard14px18-line-height, 180%);
  font-weight: var(--textcard14px18-font-weight, 400);
  position: relative;
  align-self: stretch;
}
.sct-about .about-info {
  background: var(--color-white, #ffffff);
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}
.sct-about .inr2 {
  background: var(--color-white, #ffffff);
  border-style: solid;
  border-color: var(--color-keygold, #a59450);
  border-width: 1px;
  padding: 38px 40px 44px 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-about .inr2 {
    padding: 26px 20px;
    gap: 24px;
  }
}
.sct-about .div {
  color: var(--color-black, #1f1f1f);
  text-align: center;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 24px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
  align-self: stretch;
}
.sct-about .list {
  border-style: solid;
  border-color: var(--color-graycccccc, #cccccc);
  border-width: 1px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sct-about .dl-company {
  border-style: solid;
  border-color: var(--color-graycccccc, #cccccc);
  border-width: 0px 0px 1px 0px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-about .dl-company {
    flex-direction: column;
  }
}
.sct-about .dt {
  background: rgba(238, 235, 224, 0.5);
  padding: 6px 18px 6px 18px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 170px;
  min-height: 56px;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-about .dt {
    width: 100%;
    padding: 8px 12px 8px 12px;
    min-height: 36px;
    text-align: left;
    justify-content: flex-start;
  }
}
.sct-about .div2 {
  color: var(--color-black, #1f1f1f);
  text-align: center;
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1199px) {
  .sct-about .div2 {
    font-size: 15px;
    text-align: left;
    justify-content: flex-start;
  }
}
.sct-about .dd {
  padding: 6px 18px 6px 18px;
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  min-height: 56px;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-about .dd {
    padding: 16px 12px;
    align-items: flex-start;
    width: 100%;
  }
}
.sct-about .div3 {
  color: var(--color-black, #1f1f1f);
  text-align: left;
  font-size: 16px;
  line-height: 140%;
  font-weight: 300;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sct-about .div4 {
  color: var(--color-black, #1f1f1f);
  text-align: center;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sct-about .div-4-span {
  color: var(--color-black, #1f1f1f);
  font-size: 16px;
  line-height: 140%;
  font-weight: 600;
}
.sct-about .div-4-span2 {
  color: var(--color-black, #1f1f1f);
  font-size: 13px;
  line-height: 140%;
  font-weight: 300;
}

.sct-case {
  position: relative;
  overflow: hidden;
}
.sct-case .sct_inner {
  background: #fff;
  position: relative;
  padding: 64px 22px;
}
@media (min-width: 641px) {
  .sct-case .sct_inner {
    padding: 64px 38px;
  }
}
@media (min-width: 1200px) {
  .sct-case .sct_inner {
    padding: 110px 48px;
  }
}
.sct-case .sct_inner_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.sct-case .sct_inner_bg_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.sct-case .sec-description {
  color: var(--color-black, #1f1f1f);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 200%;
  /* 32px */
  text-align: left;
  margin-top: 32px;
  position: relative;
}
@media (min-width: 1200px) {
  .sct-case .sec-description {
    text-align: center;
  }
}
.sct-case .note {
  max-width: 920px;
  margin: auto;
  font-style: normal;
  color: var(--color-black, #1f1f1f);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 180%;
  /* 21.6px */
  margin-top: 24px;
}
@media (min-width: 1200px) {
  .sct-case .note {
    margin-top: 32px;
  }
}
.sct-case .case2_content {
  max-width: 960px;
  margin: auto;
  margin-top: 64px;
}
@media (max-width: 1199px) {
  .sct-case .case2_content {
    margin-top: 48px;
  }
}
.sct-case .list-case,
.sct-case .list-case * {
  box-sizing: border-box;
}
.sct-case .list-case {
  max-width: 920px;
  margin: auto;
  margin-top: 64px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-case .list-case {
    margin-top: 48px;
    gap: 32px;
  }
}
.sct-case .li-card-success {
  background: var(--color-white, #ffffff);
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 440px;
  position: relative;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}
@media (max-width: 1199px) {
  .sct-case .li-card-success {
    width: 100%;
  }
}
.sct-case .inr {
  border-style: solid;
  border-color: var(--color-keygold, #a59450);
  border-width: 1px;
  padding: 38px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-case .inr {
    gap: 20px;
    padding: 20px 16px;
  }
}
.sct-case .ttl {
  background: var(--color-keyred, #d10000);
  padding: 4px 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  height: 40px;
  position: relative;
}
.sct-case .div {
  color: var(--color-white, #ffffff);
  text-align: center;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 20px;
  line-height: 140%;
  font-weight: 600;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1199px) {
  .sct-case .div {
    font-size: 19px;
  }
}
.sct-case .description {
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sct-case .income {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.sct-case .div2 {
  color: var(--color-black, #1f1f1f);
  text-align: left;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 22px;
  line-height: 140%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 1199px) {
  .sct-case .div2 {
    font-size: 20px;
  }
}
.sct-case .amount {
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.sct-case ._835 {
  color: var(--color-black, #1f1f1f);
  text-align: left;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 40px;
  line-height: 100%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 1199px) {
  .sct-case ._835 {
    font-size: 38px;
  }
}
.sct-case .div3 {
  color: var(--color-black, #1f1f1f);
  text-align: center;
  font-size: 14px;
  line-height: 140%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sct-case .batch {
  background: var(--color-keygold, #a59450);
  padding: 2px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 78px;
  height: 78px;
  position: absolute;
  right: -14px;
  bottom: -14px;
  overflow: hidden;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}
@media (max-width: 1199px) {
  .sct-case .batch {
    width: 66px;
    height: 66px;
    right: -16px;
    bottom: -16px;
  }
}
.sct-case .li-case2 + .li-case2 {
  margin-top: 56px;
}
@media (max-width: 1199px) {
  .sct-case .li-case2 + .li-case2 {
    margin-top: 40px;
  }
}
.sct-case .li-case2 {
  background: var(--color-white, #ffffff);
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}
.sct-case .li-case2 .head {
  background: var(--color-keyred, #d10000);
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sct-case .li-case2 .icon-success-01-svg-fill {
  background: var(--color-keygold, #a59450);
  padding: 0px 3px 0px 3px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  width: 80px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .sct-case .li-case2 .icon-success-01-svg-fill {
    position: absolute;
    top: -8px;
    left: -8px;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
    width: 100px;
    height: 36px;
    flex-direction: row;
  }
}
.sct-case .li-case2 .case {
  color: var(--white, #ffffff);
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 100%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sct-case .li-case2 ._1 {
  color: var(--white, #ffffff);
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  line-height: 100%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sct-case .li-case2 .frame-392 {
  padding: 12px 16px 14px 16px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-case .li-case2 .frame-392 {
    padding: 36px 16px 16px 16px;
  }
}
.sct-case .li-case2 .div {
  color: var(--color-white, #ffffff);
  text-align: left;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 22px;
  line-height: 140%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 1199px) {
  .sct-case .li-case2 .div {
    font-size: 20px;
  }
}
.sct-case .li-case2 .a-50 {
  color: var(--color-white, #ffffff);
  text-align: left;
  font-size: 14px;
  line-height: 140%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sct-case .li-case2 .detail {
  padding: 44px 40px 44px 40px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-case .li-case2 .detail {
    gap: 24px;
    padding: 26px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.sct-case .li-case2 .img-case-02-01 {
  flex-shrink: 0;
  width: 220px;
  height: 220px;
  position: relative;
  object-fit: cover;
}
.sct-case .li-case2 .description {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.sct-case .li-case2 .list-profile {
  border-style: solid;
  border-color: #9c9c9c;
  border-width: 0px 0px 1px 0px;
  padding: 0px 0px 10px 0px;
  display: flex;
  flex-direction: row;
  gap: 2px 24px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
  flex-wrap: wrap;
}
@media (max-width: 1199px) {
  .sct-case .li-case2 .list-profile {
    gap: 2px 14px;
  }
}
@media (max-width: 640px) {
  .sct-case .li-case2 .list-profile {
    flex-direction: column;
    align-items: flex-start;
  }
}
.sct-case .li-case2 .li {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.sct-case .li-case2 .li-point {
  background: var(--color-keygold, #a59450);
  border-radius: 7px;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  position: relative;
}
.sct-case .li-case2 .detail_item {
  color: var(--color-black, #1f1f1f);
  text-align: left;
  font-size: 14px;
  line-height: 140%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sct-case .li-case2 .a-60 {
  color: var(--color-black, #1f1f1f);
  text-align: left;
  font-size: var(--textcard14px18-font-size, 14px);
  line-height: var(--textcard14px18-line-height, 180%);
  font-weight: var(--textcard14px18-font-weight, 400);
  position: relative;
  align-self: stretch;
}
.sct-case .li-case2 .point {
  background: #f1f1f1;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-case .li-case2 .point {
    flex-direction: column;
  }
}
.sct-case .li-case2 .point_wrap {
  border-style: solid;
  border-color: var(--color-keygold, #a59450);
  border-width: 1px;
  padding: 14px 8px 14px 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-case .li-case2 .point_wrap {
    width: 100%;
    height: 24px;
  }
}
.sct-case .li-case2 .point2 {
  color: var(--color-keygold, #a59450);
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 120%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sct-case .li-case2 .a-a {
  color: var(--color-black, #1f1f1f);
  text-align: left;
  font-size: var(--textcard14px18-font-size, 14px);
  line-height: var(--textcard14px18-line-height, 180%);
  font-weight: var(--textcard14px18-font-weight, 400);
  position: relative;
  flex: 1;
}
.sct-case .li-case2 .detail_uturn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white, #fff);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 16.8px */
  border-radius: 10px;
  border: 1px solid var(--color-key_gold, #a59450);
  background: var(--color-key_gold, #a59450);
  padding: 0 16px;
}

.sct-data {
  position: relative;
  overflow: hidden;
}
.sct-data .sct_inner {
  background: #fff;
  position: relative;
  padding: 64px 22px;
}
@media (min-width: 641px) {
  .sct-data .sct_inner {
    padding: 64px 38px;
  }
}
@media (min-width: 1200px) {
  .sct-data .sct_inner {
    padding: 110px 48px;
  }
}
.sct-data .section_content {
  max-width: 976px;
  margin: auto;
  margin-top: 64px;
}
.sct-data .list-data {
  display: flex;
  flex-direction: row;
  gap: 56px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-data .list-data {
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
  }
}
.sct-data .li-card-data {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 288px;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-data .li-card-data {
    width: 100%;
  }
}
.sct-data .img-data-graph {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 288px;
  height: 288px;
  position: relative;
}
.sct-data .img-data-graph-01 {
  flex-shrink: 0;
  width: 288px;
  height: 288px;
  position: relative;
  overflow: visible;
}
.sct-data .description {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sct-data .ttl {
  background: var(--color-keygold, #a59450);
  padding: 0px 16px 2px 16px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 180px;
  height: 32px;
  position: relative;
}
.sct-data .div {
  color: var(--color-white, #ffffff);
  text-align: center;
  font-size: 18px;
  line-height: 120%;
  font-weight: 600;
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sct-data ._70-40-50-40-60 {
  color: var(--color-black, #1f1f1f);
  text-align: left;
  font-size: 16px;
  line-height: 180%;
  font-weight: 300;
  position: relative;
  align-self: stretch;
}
.sct-data .img-data-graph-02 {
  flex-shrink: 0;
  width: 288px;
  height: 288px;
  position: relative;
  overflow: visible;
}
.sct-data .img-data-graph-03 {
  flex-shrink: 0;
  width: 288px;
  height: 288px;
  position: relative;
  overflow: visible;
}
@media (max-width: 1199px) {
  .sct-data .img-data-graph-03 {
    width: 300px;
    height: 300px;
  }
}

.sct-flow {
  position: relative;
  overflow: hidden;
  background: #f1f1f1;
}
.sct-flow .sct_inner {
  padding: 80px 24px;
}
@media (min-width: 769px) {
  .sct-flow .sct_inner {
    padding-top: 120px;
  }
}

.o-flow_wrap {
  margin-top: 56px;
}
@media (min-width: 769px) {
  .o-flow_wrap {
    max-width: 1120px;
    margin: auto;
    margin-top: 80px;
    display: flex;
    justify-content: center;
  }
}

.m-flow_item {
  position: relative;
}
.m-flow_item + .m-flow_item {
  margin-top: 72px;
}
.m-flow_item:nth-child(2) .m-flow_item_step {
  background: #b9a87b;
}
.m-flow_item:nth-child(3) .m-flow_item_step {
  background: #7a7159;
}
.m-flow_item:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -48px;
  margin: auto;
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/top/icon_arrow2.svg);
  transform: rotate(90deg);
}
.m-flow_item picture {
  display: block;
  max-width: 216px;
  margin: auto;
  margin-top: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.m-flow_item h3 {
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  margin-top: 40px;
}
.m-flow_item p {
  font-family: "YuGothic";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.8;
  margin-top: 24px;
  text-align: center;
}
@media (min-width: 769px) {
  .m-flow_item {
    flex: 1 1 calc(33.33% - 53.3333333333px);
  }
  .m-flow_item + .m-flow_item {
    margin-top: 0;
    margin-left: 80px;
  }
  .m-flow_item:not(:last-child)::after {
    transform: none;
    width: 24px;
    top: 0;
    bottom: 0;
    right: -52px;
    left: auto;
  }
}

.m-flow_item_step {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: #c9ad5f;
  padding: 4px;
}
.m-flow_item_step span:nth-child(1) {
  display: inline-block;
  padding-bottom: 4px;
  font-family: "Albert Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
}
.m-flow_item_step span:nth-child(2) {
  display: inline-block;
  margin-left: 8px;
  font-family: "Albert Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  color: #ffffff;
}

.sct-intro {
  position: relative;
  background: #dfddd6;
  padding: 0 38px 18px;
}
@media (min-width: 1200px) {
  .sct-intro {
    padding-bottom: 14px;
  }
}
.sct-intro .block {
  max-width: 954px;
  margin: auto;
  transform: translateY(-50px);
  background: var(--color-white, #ffffff);
  padding: 3px;
  display: flex;
  flex-direction: row;
  gap: 95px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}
@media (max-width: 1199px) {
  .sct-intro .block {
    transform: translateY(-38px);
  }
}
.sct-intro .inr {
  border-style: solid;
  border-color: var(--color-keygold, #a59450);
  border-width: 1px;
  padding: 32px 40px 32px 40px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-intro .inr {
    flex-direction: column;
    gap: 24px;
    padding: 28px 18px 20px 18px;
  }
}
.sct-intro .logo-gogin-careerdesign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
  max-width: 240px;
}
@media (max-width: 1199px) {
  .sct-intro .logo-gogin-careerdesign {
    max-width: 288px;
  }
}
.sct-intro .symbol-gogin {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  position: relative;
  overflow: visible;
}
.sct-intro .image-17 {
  flex-shrink: 0;
  width: 240px;
  height: 20px;
  position: relative;
  object-fit: cover;
}
.sct-intro .div-p-intro-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
.sct-intro .ttl {
  border-style: solid;
  border-color: var(--color-black, #1f1f1f);
  border-width: 0px 0px 1px 0px;
  padding: 0px 4px 6px 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sct-intro .div {
  text-align: left;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 24px;
  line-height: 140%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sct-intro .div-span {
  color: var(--color-keyred, #d10000);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 24px;
  line-height: 140%;
  font-weight: 600;
}
.sct-intro .div-span2 {
  color: var(--color-black, #1f1f1f);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 24px;
  line-height: 140%;
  font-weight: 600;
}
.sct-intro .list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sct-intro .li {
  padding: 0px 0px 0px 18px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sct-intro .div2 {
  color: var(--color-black, #1f1f1f);
  text-align: left;
  font-family: "HiraginoKakuGothicPro-W3", sans-serif;
  font-size: 17px;
  line-height: 160%;
  font-weight: 400;
  position: relative;
  flex: 1;
}
.sct-intro .li-point {
  background: var(--color-keygold, #a59450);
  border-radius: 8px;
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0px;
  top: 8.5px;
}

.sct-job_information {
  position: relative;
  overflow: hidden;
}
.sct-job_information .sct_inner {
  background: #fff;
  position: relative;
  padding: 64px 22px;
}
@media (min-width: 641px) {
  .sct-job_information .sct_inner {
    padding: 64px 38px;
  }
}
@media (min-width: 1200px) {
  .sct-job_information .sct_inner {
    padding: 110px 48px;
  }
}
.sct-job_information .sct_inner_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.sct-job_information .sec-description {
  position: relative;
  color: var(--color-black, #1f1f1f);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 200%;
  /* 32px */
  text-align: left;
  margin-top: 24px;
}
@media (min-width: 1200px) {
  .sct-job_information .sec-description {
    text-align: center;
    margin-top: 32px;
  }
}
.sct-job_information .income,
.sct-job_information .income * {
  box-sizing: border-box;
}
.sct-job_information .income {
  background: var(--color-keygold, #a59450);
  border-style: solid;
  border-color: var(--color-keygold, #a59450);
  border-width: 1px;
  padding: 0px 20px 0px 20px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 36px;
  position: relative;
}
.sct-job_information .icon-income {
  background: var(--color-white, #ffffff);
  padding: 1px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 46px;
  height: 20px;
  position: relative;
}
.sct-job_information .div {
  color: var(--color-black, #1f1f1f);
  text-align: left;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sct-job_information .num {
  color: var(--color-white, #ffffff);
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sct-job_information .num-span {
  color: var(--color-white, #ffffff);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
}
.sct-job_information .num-span2 {
  color: var(--color-white, #ffffff);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
}
.sct-job_information .num-span3 {
  color: var(--color-white, #ffffff);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
}
.sct-job_information .num-span4 {
  color: var(--color-white, #ffffff);
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
}

.o-job_information_lists {
  position: relative;
  margin-top: 48px;
  left: calc(50% + 22px);
  margin-left: -22px;
  margin-right: -22px;
  transform: translateX(-50%);
  overflow: hidden;
}
@media (min-width: 641px) {
  .o-job_information_lists {
    left: calc(50% + 38px);
    margin-left: -38px;
    margin-right: -38px;
  }
}
.o-job_information_lists .swiper-wrapper {
  margin: auto;
}
.o-job_information_lists .note {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.8;
  text-align: right;
  margin-top: 24px;
  padding-right: 24px;
}
@media (min-width: 1200px) {
  .o-job_information_lists {
    margin: auto;
    margin-top: 64px;
    max-width: 1200px;
    left: 0;
    transform: none;
  }
  .o-job_information_lists .note {
    margin-top: 32px;
    padding-right: 0;
  }
}

.m-job_information_list .slide {
  max-width: 300px;
  height: 100%;
  margin: auto;
  border-bottom: 4px solid var(--color-key_red, #d10000);
  background: var(--color-white, #fff);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
}
@media (min-width: 1200px) {
  .m-job_information_list {
    flex: 1 1 calc(25% - 24px);
  }
  .m-job_information_list + .m-job_information_list {
    margin-left: 32px;
  }
}

.list_inner {
  background: #fff;
}
.list_inner h3 {
  color: var(--color-key_red, #d10000);
  text-align: center;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  /* 26.6px */
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.job_name {
  display: block;
  padding: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(93.92deg, #786432 -12.67%, #9a8449 27.55%, #88784e 106.2%);
}

.job_text {
  padding: 24px;
}
.job_text h4 {
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.8;
  color: var(--color-black, #1f1f1f);
  text-align: left;
}
.job_text p {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.8;
  margin-top: 8px;
  text-align: left;
}

.incom_tag {
  display: inline-block;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  padding: 4px 12px;
  background: #7a7159;
  margin-top: 16px;
}

.incom_num {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.5;
}
.incom_num .-font-s {
  font-size: 18px;
}

/* スライダー非活性時 */
.swiper:not(.swiper-initialized) {
  padding: 0;
}
.swiper:not(.swiper-initialized) ~ .swiper-button-prev, .swiper:not(.swiper-initialized) ~ .swiper-button-next {
  display: none;
}
.swiper:not(.swiper-initialized) .swiper-wrapper {
  display: block;
}
.swiper:not(.swiper-initialized) .swiper-pagination-bullet {
  display: none;
}

.js-slider .swiper:not(.swiper-initialized) {
  overflow: initial;
}
.js-slider .swiper:not(.swiper-initialized) .swiper-wrapper {
  display: flex;
}
.js-slider .swiper:not(.swiper-initialized) .swiper-slide {
  height: auto;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 15px;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.swiper-button-next,
.swiper-button-prev {
  width: 40px;
  top: 210px;
}

.swiper-button-next {
  right: -28px;
}

.swiper-button-prev {
  left: -28px;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "";
  width: 40px;
  height: 40px;
  background-color: #d10000;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  background-image: url(../img/top/job/prev.svg);
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "";
  width: 40px;
  height: 40px;
  background-color: #d10000;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center;
  background-image: url(../img/top/job/prev.svg);
}

@media (max-width: 1199px) {
  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
    max-width: 300px;
  }

  .js-slider {
    overflow: hidden;
  }
  .js-slider .slide {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.swiper-button_wrap {
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 300px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
}
@media (min-width: 1200px) {
  .swiper-button_wrap {
    display: none;
  }
}

.sct-merit {
  position: relative;
  overflow: hidden;
}
.sct-merit .sct_inner {
  background: #fff;
  position: relative;
  padding: 64px 22px;
}
@media (min-width: 641px) {
  .sct-merit .sct_inner {
    padding: 64px 38px;
  }
}
@media (min-width: 1200px) {
  .sct-merit .sct_inner {
    padding: 110px 48px;
  }
}
.sct-merit .section_content {
  margin: auto;
  margin-top: 64px;
  max-width: 1200px;
}
@media (max-width: 1199px) {
  .sct-merit .section_content {
    margin-top: 48px;
  }
}
.sct-merit .block,
.sct-merit .block * {
  box-sizing: border-box;
}
.sct-merit .block {
  display: flex;
  flex-direction: column;
  gap: 64px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sct-merit .li-card-merit {
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sct-merit .li-card-merit.-reverse {
  flex-direction: row-reverse;
}
@media (max-width: 1199px) {
  .sct-merit .li-card-merit {
    flex-direction: column;
    gap: 0;
  }
  .sct-merit .li-card-merit.-reverse {
    flex-direction: column;
  }
}
.sct-merit .img-merit-01 {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}
.sct-merit .img-merit-0 {
  flex-shrink: 0;
  width: 620px;
  height: 380px;
  position: relative;
  object-fit: cover;
}
@media (max-width: 1199px) {
  .sct-merit .img-merit-0 {
    width: 100%;
    height: auto;
  }
}
.sct-merit .description-merit {
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  position: relative;
}
@media (max-width: 1199px) {
  .sct-merit .description-merit {
    margin-top: -16px;
    gap: 16px;
  }
}
.sct-merit .ttl {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-shrink: 0;
  position: relative;
}
.sct-merit .ttl-en {
  background: var(--color-keyred, #d10000);
  padding: 0px 4px 0px 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 150px;
  height: 44px;
  position: relative;
}
.sct-merit .merit {
  color: var(--color-white, #ffffff);
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  line-height: 100%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 1199px) {
  .sct-merit .merit {
    font-size: 18px;
  }
}
.sct-merit ._1 {
  color: var(--color-white, #ffffff);
  text-align: left;
  font-family: Montserrat, sans-serif;
  font-size: 36px;
  line-height: 100%;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sct-merit .div {
  color: var(--color-black, #1f1f1f);
  text-align: left;
  font-family: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", serif;
  font-size: 24px;
  line-height: 150%;
  font-weight: 600;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 1199px) {
  .sct-merit .div {
    font-size: 20px;
  }
}
.sct-merit .div2 {
  color: var(--color-black, #1f1f1f);
  text-align: left;
  font-size: var(--textbase15px-font-size, 15px);
  line-height: var(--textbase15px-line-height, 200%);
  font-weight: var(--textbase15px-font-weight, 400);
  position: relative;
  align-self: stretch;
}

.sct-success {
  position: relative;
  overflow: hidden;
}
.sct-success .sct_inner {
  padding: 80px 24px;
}
@media (min-width: 769px) {
  .sct-success .sct_inner {
    padding: 120px 80px;
  }
}

.o-success_lists {
  max-width: 1280px;
  margin: auto;
  margin-top: 56px;
  position: relative;
}
@media (min-width: 769px) {
  .o-success_lists {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.m-success_list {
  position: relative;
  background: #fff;
  box-shadow: 2px 2px 16px rgba(164, 169, 180, 0.6);
  border: 1px solid #000;
  border-image: var(--global-gradation_gold2);
  border-image-slice: 1;
  max-width: 400px;
  margin: 0 auto;
}
.m-success_list + .m-success_list {
  margin-top: 40px;
}
@media (min-width: 769px) {
  .m-success_list {
    flex: 1 1 calc(33.333% - 26.6666666667px);
  }
  .m-success_list + .m-success_list {
    margin-top: 0;
    margin-left: 40px;
  }
}
.m-success_list::before {
  content: "";
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 8px;
  background: #c9ad5f;
}
.m-success_list .tag {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 120px;
  font-family: "Albert Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  color: #ffffff;
  background: #c9ad5f;
  padding: 5px 12px 4px;
}
.m-success_list .tag .num {
  display: inline-block;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  margin-left: 12px;
}

.m-success_list_inner {
  padding: 48px 24px 40px;
}
.m-success_list_inner h3 {
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  margin-top: 24px;
}

.m-success_list_text {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.8;
  margin-top: 8px;
}

.m-prof {
  display: flex;
}
.m-prof picture {
  display: block;
  width: 88px;
}
.m-prof .textbox {
  flex: auto;
  position: relative;
  margin-left: 16px;
}
.m-prof .textbox p {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: #7a7159;
  text-align: left;
  margin-top: 19px;
}
.m-prof .textbox p + p {
  margin-top: 16px;
}
.m-prof .textbox .name {
  font-size: 14px;
  line-height: 1.8;
}
.m-prof .textbox .prof_tag {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  background: #7a7159;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: #ffffff;
  padding: 1px 2px 2px;
}

.m-success_list_point {
  position: relative;
  padding-top: 20px;
  padding-bottom: 16px;
  margin-top: 25px;
  border-top: 2px solid #c9ad5f;
  border-bottom: 2px solid #c9ad5f;
}
.m-success_list_point span {
  position: absolute;
  top: -17px;
  left: 0;
  display: inline-block;
  background: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.8;
  color: #c9ad5f;
  padding-right: 8px;
}
.m-success_list_point p {
  font-family: YuGothic, "Yu Gothic", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.8;
}

.sct-main {
  position: relative;
  background-image: url(../img/top/main/bg_j.jpg);
  background-repeat: repeat-x;
  background-size: contain;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .sct-main {
    height: 644px;
  }
}
.sct-main .inr {
  display: none;
  max-width: 1080px;
  height: 100%;
  margin: auto;
  position: relative;
}
@media (min-width: 1200px) {
  .sct-main .inr {
    display: block;
  }
}

.img-main-sp {
  display: block;
}
@media (min-width: 1200px) {
  .img-main-sp {
    display: none;
  }
}

.img-main-pc {
  position: absolute;
  height: calc(100% + 76px);
  object-fit: cover;
  bottom: 0;
  display: none;
}
@media (min-width: 1200px) {
  .img-main-pc {
    display: block;
  }
}

.ttl-main {
  position: relative;
  margin-top: 72px;
  z-index: 1;
}

.img-main-person {
  position: absolute;
  right: -48px;
  bottom: 0;
  max-width: 686px;
}

.img-main-ttl {
  max-width: 434px;
}

.img-main-ttl_catch {
  max-width: 502px;
  margin-top: 24px;
}

.img-main-ttl_batch {
  max-width: 354px;
  margin-top: 40px;
}

.mv {
  display: block;
  height: auto;
  box-sizing: border-box;
  padding-bottom: 24px;
}
.mv img {
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
@media (min-width: 769px) {
  .mv {
    padding-bottom: 0;
    height: 100%;
  }
}

.o-mv_wrap {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 32px;
  padding-left: 14px;
  width: 100%;
}
@media (min-width: 769px) {
  .o-mv_wrap {
    max-width: 1440px;
    margin: auto;
    right: 0;
    padding-top: 104px;
    padding-left: 96px;
  }
}

.mv_label {
  width: 193px;
}
@media (min-width: 769px) {
  .mv_label {
    width: 757px;
  }
}

.subttl {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  margin-top: 8px;
}
@media (min-width: 769px) {
  .subttl {
    font-size: 24px;
    line-height: 140%;
    margin-top: 16px;
  }
}

.mainttl {
  position: relative;
  display: block;
  margin-top: 4px;
  font-weight: 400;
  font-size: 26px;
  line-height: 130%;
  letter-spacing: -0.05em;
  color: #ffffff;
  z-index: 10;
}
.mainttl span {
  font-size: 58px;
  line-height: 130%;
}
.mainttl::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../img/top/image_map_hiroshima.svg);
  z-index: -1;
  opacity: 1;
}
@media (min-width: 769px) {
  .mainttl {
    font-size: 48px;
    line-height: 140%;
    margin-top: 32px;
  }
  .mainttl span {
    font-size: 80px;
    line-height: 140%;
  }
  .mainttl::before {
    top: -24px;
    width: 209px;
    height: 197px;
  }
}