@use 'css/abstracts' as *;

.sct-main {
  position: relative;
	background-image: url(../img/top/main/bg_j.jpg);
  background-repeat: repeat-x;
  background-size: contain;
  overflow: hidden;
	@media (min-width: 1200px) {
		height: 644px;
	}
  .inr{
    display: none;
    max-width: 1080px;
    height: 100%;
    margin: auto;
    position: relative;
    @media (min-width: 1200px) {
      display: block;
    }
  }
}
.img-main-sp{
  display: block;
  @media (min-width: 1200px) {
    display: none;
  }
}
.img-main-pc{
  position: absolute;
    height: calc(100% + 76px);
    object-fit: cover;
    bottom: 0;
    display: none;
    @media (min-width: 1200px) {
      display: block;
    }
}
.ttl-main{
  position: relative;
  margin-top: 72px;
  z-index: 1;
}
.img-main-person{
  position: absolute;
  right: -48px;
  bottom: 0;
  max-width: 686px;
}
.img-main-ttl{
  max-width: 434px;
}
.img-main-ttl_catch{
max-width: 502px;
margin-top: 24px;
}
.img-main-ttl_batch{
max-width: 354px;
margin-top: 40px;
}



.mv {
	display: block;
	height: auto;
	box-sizing: border-box;
	padding-bottom: 24px;
	img{
		display: block;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
	@media (min-width: 769px) {
		padding-bottom: 0;
		height: 100%;

	}
}
.o-mv_wrap {
	position: absolute;
	top: 0;
	left: 0;
	padding-top: 32px;
	padding-left: 14px;
	width: 100%;
	@media (min-width: 769px) {
		max-width: 1440px;
		margin: auto;
		right: 0;
		padding-top: 104px;
		padding-left: 96px;
	}
}
.mv_label {
	width: 193px;
	@media (min-width: 769px) {
		width: 757px;
	}
}
.subttl {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 130%;
	margin-top: 8px;
	@media (min-width: 769px) {
		font-size: 24px;
		line-height: 140%;
		margin-top: 16px;
	}
}
.mainttl {
	position: relative;
	display: block;
	margin-top: 4px;
	font-weight: 400;
	font-size: 26px;
	line-height: 130%;
	letter-spacing: -0.05em;
	color: #ffffff;
	z-index: 10;
	span {
		font-size: 58px;
		line-height: 130%;
	}
	&::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 160px;
		height: 120px;
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/top/image_map_hiroshima.svg);
		z-index: -1;
		opacity: 1;
	}

	@media (min-width: 769px) {
		font-size: 48px;
		line-height: 140%;
		margin-top: 32px;
		span {
			font-size: 80px;
			line-height: 140%;
		}
			&::before{
				top: -24px;
				width: 209px;
				height: 197px;
			}
	}
}
