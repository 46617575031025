@use "./root";

/* スライダー非活性時 */
    .swiper:not(.swiper-initialized) {
        padding: 0;
        & ~ .swiper-button-prev,
        & ~ .swiper-button-next {
            display: none;
        }
        .swiper-wrapper {
            display: block;
        }
        .swiper-pagination-bullet{
          display: none;
        }
    }
    .js-slider {
       .swiper:not(.swiper-initialized) {
        overflow: initial;
        .swiper-wrapper {
            display: flex;
        }
          .swiper-slide{
          height: auto;
       }
    }

    }
    .swiper-pagination-bullets.swiper-pagination-horizontal{
      bottom: 15px;
    }
    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled{
      opacity: 0;
    }
    .swiper-button-next,
    .swiper-button-prev{
      width: 36px;
    }
    .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
      content: '';
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../img/top/icon_arrow_l.svg);
    }
    .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
      content: '';
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../img/top/icon_arrow_l.svg);
      transform: rotate(180deg);
    }
    // .swiper-pagination-horizontal.swiper-pagination-bullets {
    //   .swiper-pagination-bullet{
    //     width: 18px;
    //     height: 18px;
    //     margin: 0 11px;
    //     background: $wht;
    //     opacity: 1;
    //   }
    //   .swiper-pagination-bullet-active{
    //     background: $ylw;
    //   }
    // }
   	@media (max-width: 768px) {
   .swiper {
        overflow: visible; // 1.はみ出させるように visible で上書き
        //        padding-left: calc(26 / 375 * 100%);
        // padding-right: calc(26 / 375 * 100%);
    }
    .swiper-slide {
        // width: 298px;
        // margin-bottom: 62px;
        height: auto;// 100%だと高さがインラインに
    }
    .js-slider{
           overflow: hidden; // 2.代わりに親要素で hidden にする
          .slide{
            height: 100%;
            display: flex;
            flex-direction: column;
          }
        }
    }