@use 'css/abstracts' as *;
.sct-success {
	position: relative;
	overflow: hidden;

	.sct_inner {
		padding: 80px 24px;
		@media (min-width: 769px) {
			padding: 120px 80px;
		}
	}
}

.o-success_lists {
	max-width: 1280px;
	margin: auto;
	margin-top: 56px;
	position: relative;
	@media (min-width: 769px) {
		margin-top: 80px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.m-success_list {
	position: relative;
	background: #fff;
	box-shadow: 2px 2px 16px rgba(164, 169, 180, 0.6);
	border: 1px solid #000;
	border-image: var(--global-gradation_gold2);
	border-image-slice: 1;
	max-width: 400px;
	margin: 0 auto;
	& + .m-success_list {
		margin-top: 40px;
	}
	@media (min-width: 769px) {
		flex: 1 1 calc(33.333% - 40px * 2 / 3);
		& + .m-success_list {
			margin-top: 0;
			margin-left: 40px;
		}
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 8px;
		background: #c9ad5f;
	}
	.tag {
		position: absolute;
		top: 0;
		right: 0;
		display: inline-block;
		width: 120px;
		font-family: 'Albert Sans', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 100%;
		color: #ffffff;
		background: #c9ad5f;
		padding: 5px 12px 4px;
		.num {
			display: inline-block;
			font-weight: 400;
			font-size: 32px;
			line-height: 100%;
			margin-left: 12px;
		}
	}
}
.m-success_list_inner {
	padding: 48px 24px 40px;
	h3 {
		display: block;
		font-weight: 700;
		font-size: 20px;
		line-height: 140%;
		margin-top: 24px;
	}
}
.m-success_list_text {
	font-family: YuGothic, 'Yu Gothic', sans-serif;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.8;
	margin-top: 8px;
}
.m-prof {
	display: flex;
	picture {
		display: block;
		width: 88px;
	}
	.textbox {
		flex: auto;
		position: relative;
		margin-left: 16px;
		p {
			font-weight: 700;
			font-size: 16px;
			line-height: 140%;
			color: #7a7159;
			text-align: left;
			margin-top: 19px;
			& + p {
				margin-top: 16px;
			}
		}
		.name {
			font-size: 14px;
			line-height: 1.8;
		}
		.prof_tag {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			background: #7a7159;
			font-weight: 700;
			font-size: 14px;
			line-height: 100%;
			color: #ffffff;
			padding: 1px 2px 2px;
		}
	}
}
.m-success_list_point {
	position: relative;
	padding-top: 20px;
	padding-bottom: 16px;
	margin-top: 25px;
	border-top: 2px solid #c9ad5f;
	border-bottom: 2px solid #c9ad5f;
	span {
		position: absolute;
		top: -17px;
		left: 0;
		display: inline-block;
		background: #fff;
		font-weight: 700;
		font-size: 20px;
		line-height: 1.8;
		color: #c9ad5f;
		padding-right: 8px;
	}
	p {
		font-family: YuGothic, 'Yu Gothic', sans-serif;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.8;
	}
}
