@use "css/funcion" as *;
// Domain Tokens
:root {
  // Color
  --global-form-color-text: var(--global-color-text);
  --global-form-color-text-contract: var(--global-color-gray-200);
  --global-form-color-placeholder: var(--global-color-gray-400);
  --global-form-color-warn: var(--global-color-warn);
  --global-form-color-warn-contract: var(--global-color-warn-contract);
  --global-form-color-active: var(--global-color-warn);
  --global-form-color-active-contract: var(--global-color-warn-contract);
  // Typography
  --global-form-typofgraphy-fontSize-xs: #{rem(10)};
  --global-form-typofgraphy-fontSize-sm: #{rem(12)};
  --global-form-typofgraphy-fontSize: var(--global-typography-fontSize-default);
  --global-form-typofgraphy-lineHeight: 1.5;
  --global-form-typofgraphy-lineHeight-lg: 1.8;
  --global-form-typofgraphy-lineHeight-xl: 2;
  // Layout
  --global-form-container-inner-gutter: 16px;
  // Element
  --global-form-element-min-height: 42px;
  --global-form-element-input-gap-x: 16px;
  --global-form-element-input-gap-x-lg: 12px;
  --global-form-element-input-gap-x-xl: 16px;
  --global-form-element-input-gap-x-2xl: 20px;
  --global-form-element-input-gap-x-3xl: 24px;
  --global-form-element-input-gap-y: 16px;
  --global-form-element-input-gap-y-lg: 12px;
  --global-form-element-input-gap-y-xl: 16px;
  --global-form-element-input-gap-y-2xl: 20px;
  --global-form-element-input-gap-y-3xl: 24px;
  --global-form-element-input-gap-y-4xl: 28px;
  --global-form-element-input-gap-y-5xl: 32px;
  // state
  --global-form-state-readonly-opacity: .5;
  --global-form-state-disabled-opacity: .5;
}
