@use 'css/abstracts' as *;
.sct-case {
	position: relative;
	overflow: hidden;

	.sct_inner {
		background: #fff;
		position: relative;
		padding: 64px 22px;
		@media (min-width: 641px) {
			padding: 64px 38px;
		}
		@media (min-width: 1200px) {
			padding: 110px 48px;
		}
	}
  .sct_inner_bg {
		position: absolute;
		top: 0;
    left: 0;
		width: 100%;
	}
  .sct_inner_bg_bottom {
		position: absolute;
		bottom: 0;
    left: 0;
		width: 100%;
	}
	.sec-description {
		color: var(--color-black, #1f1f1f);
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 200%; /* 32px */
		text-align: left;
		margin-top: 32px;
    position: relative;
		@media (min-width: 1200px) {
			text-align: center;
		}
	}
	.note {
		max-width: 920px;
		margin: auto;
		font-style: normal;
		color: var(--color-black, #1f1f1f);
		text-align: right;

		font-size: 12px;
		font-style: normal;
		font-weight: 300;
		line-height: 180%; /* 21.6px */
		margin-top: 24px;
		@media (min-width: 1200px) {
			margin-top: 32px;
		}
	}
	.case2_content {
		max-width: 960px;
		margin: auto;
		margin-top: 64px;
		@media (max-width: 1199px) {
			margin-top: 48px;
		}
	}
	.list-case,
	.list-case * {
		box-sizing: border-box;
	}

	.list-case {
		max-width: 920px;
		margin: auto;
		margin-top: 64px;
		display: flex;
		flex-direction: row;
		gap: 40px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		@media (max-width: 1199px) {
			margin-top: 48px;
			gap: 32px;
		}
	}

	.li-card-success {
		background: var(--color-white, #ffffff);
		padding: 4px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		width: 440px;
		position: relative;
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
		@media (max-width: 1199px) {
			width: 100%;
		}
	}

	.inr {
		border-style: solid;
		border-color: var(--color-keygold, #a59450);
		border-width: 1px;
		padding: 38px 32px 32px 32px;
		display: flex;
		flex-direction: column;
		gap: 32px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		@media (max-width: 1199px) {
			gap: 20px;
			padding: 20px 16px;
		}
	}

	.ttl {
		background: var(--color-keyred, #d10000);
		padding: 4px 0;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		height: 40px;
		position: relative;
	}

	.div {
		color: var(--color-white, #ffffff);
		text-align: center;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 20px;
		line-height: 140%;
		font-weight: 600;
		position: relative;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		@media (max-width: 1199px) {
			font-size: 19px;
		}
	}

	.description {
		display: flex;
		flex-direction: column;
		gap: 14px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.income {
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: flex-end;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.div2 {
		color: var(--color-black, #1f1f1f);
		text-align: left;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 22px;
		line-height: 140%;
		font-weight: 600;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		@media (max-width: 1199px) {
			font-size: 20px;
		}
	}

	.amount {
		display: flex;
		flex-direction: row;
		gap: 2px;
		align-items: flex-end;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	._835 {
		color: var(--color-black, #1f1f1f);
		text-align: left;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 40px;
		line-height: 100%;
		font-weight: 600;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		@media (max-width: 1199px) {
			font-size: 38px;
		}
	}

	.div3 {
		color: var(--color-black, #1f1f1f);
		text-align: center;
		font-size: 14px;
		line-height: 140%;
		font-weight: 600;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.batch {
		background: var(--color-keygold, #a59450);
		padding: 2px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		width: 78px;
		height: 78px;
		position: absolute;
		right: -14px;
		bottom: -14px;
		overflow: hidden;
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
		@media (max-width: 1199px) {
			width: 66px;
			height: 66px;
			right: -16px;
			bottom: -16px;
		}
	}

	.li-case2 + .li-case2 {
		margin-top: 56px;
		@media (max-width: 1199px) {
			margin-top: 40px;
		}
	}
	.li-case2 {
		background: var(--color-white, #ffffff);
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);

		.head {
			background: var(--color-keyred, #d10000);
			display: flex;
			flex-direction: row;
			gap: 0px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}

		.icon-success-01-svg-fill {
			background: var(--color-keygold, #a59450);
			padding: 0px 3px 0px 3px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: center;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			width: 80px;
			position: relative;
			overflow: hidden;
			@media (max-width: 1199px) {
				position: absolute;
				top: -8px;
				left: -8px;
				box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
				width: 100px;
				height: 36px;
				flex-direction: row;
			}
		}

		.case {
			color: var(--white, #ffffff);
			text-align: center;
			font-family: Montserrat, sans-serif;
			font-size: 18px;
			line-height: 100%;
			font-weight: 600;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		._1 {
			color: var(--white, #ffffff);
			text-align: center;
			font-family: Montserrat, sans-serif;
			font-size: 32px;
			line-height: 100%;
			font-weight: 600;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.frame-392 {
			padding: 12px 16px 14px 16px;
			display: flex;
			flex-direction: column;
			gap: 2px;
			align-items: flex-start;
			justify-content: flex-start;
			flex: 1;
			position: relative;
			@media (max-width: 1199px) {
				padding: 36px 16px 16px 16px;
			}
		}

		.div {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
			font-size: 22px;
			line-height: 140%;
			font-weight: 600;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			@media (max-width: 1199px) {
				font-size: 20px;
			}
		}

		.a-50 {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-size: 14px;
			line-height: 140%;
			font-weight: 600;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.detail {
			padding: 44px 40px 44px 40px;
			display: flex;
			flex-direction: row;
			gap: 40px;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			@media (max-width: 1199px) {
				gap: 24px;
				padding: 26px 20px;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}
		}

		.img-case-02-01 {
			flex-shrink: 0;
			width: 220px;
			height: 220px;
			position: relative;
			object-fit: cover;
		}

		.description {
			display: flex;
			flex-direction: column;
			gap: 24px;
			align-items: flex-start;
			justify-content: flex-start;
			flex: 1;
			position: relative;
		}

		.list-profile {
			border-style: solid;
			border-color: #9c9c9c;
			border-width: 0px 0px 1px 0px;
			padding: 0px 0px 10px 0px;
			display: flex;
			flex-direction: row;
			gap: 2px 24px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			flex-wrap: wrap;
      @media (max-width: 1199px) {
       gap: 2px 14px;
      }
      @media (max-width: 640px) {
       flex-direction: column;
       align-items: flex-start;
      }

		}

		.li {
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}

		.li-point {
			background: var(--color-keygold, #a59450);
			border-radius: 7px;
			flex-shrink: 0;
			width: 8px;
			height: 8px;
			position: relative;
		}

		.detail_item {
			color: var(--color-black, #1f1f1f);
			text-align: left;
			font-size: 14px;
			line-height: 140%;
			font-weight: 600;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.a-60 {
			color: var(--color-black, #1f1f1f);
			text-align: left;
			font-size: var(--textcard14px18-font-size, 14px);
			line-height: var(--textcard14px18-line-height, 180%);
			font-weight: var(--textcard14px18-font-weight, 400);
			position: relative;
			align-self: stretch;
		}

		.point {
			background: #f1f1f1;
			padding: 16px;
			display: flex;
			flex-direction: row;
			gap: 16px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			@media (max-width: 1199px) {
				flex-direction: column;
			}
		}

		.point_wrap {
			border-style: solid;
			border-color: var(--color-keygold, #a59450);
			border-width: 1px;
			padding: 14px 8px 14px 8px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			width: 60px;
			height: 60px;
			position: relative;
			@media (max-width: 1199px) {
				width: 100%;
				height: 24px;
			}
		}

		.point2 {
			color: var(--color-keygold, #a59450);
			text-align: left;
			font-family: Montserrat, sans-serif;
			font-size: 14px;
			line-height: 120%;
			font-weight: 600;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.a-a {
			color: var(--color-black, #1f1f1f);
			text-align: left;
			font-size: var(--textcard14px18-font-size, 14px);
			line-height: var(--textcard14px18-line-height, 180%);
			font-weight: var(--textcard14px18-font-weight, 400);
			position: relative;
			flex: 1;
		}
		.detail_uturn {
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--color-white, #fff);
			text-align: center;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 140%; /* 16.8px */
			border-radius: 10px;
			border: 1px solid var(--color-key_gold, #a59450);
			background: var(--color-key_gold, #a59450);
			padding: 0 16px;
		}
	}
}
