@use 'css/abstracts' as *;
.sct-merit {
	position: relative;
	overflow: hidden;

	.sct_inner {
		background: #fff;
		position: relative;
		padding: 64px 22px;
    @media (min-width: 641px) {
			padding: 64px 38px;
		}
		@media (min-width: 1200px) {
			padding: 110px 48px;
		}
	}
	.section_content {
		margin: auto;
		margin-top: 64px;
		max-width: 1200px;
    @media (max-width: 1199px) {
     margin-top: 48px;
    }
	}
	.block,
	.block * {
		box-sizing: border-box;
	}

	.block {
		display: flex;
		flex-direction: column;
		gap: 64px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.li-card-merit {
		display: flex;
		flex-direction: row;
		gap: 48px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
    &.-reverse{
      flex-direction: row-reverse;
    }
    @media (max-width: 1199px) {
      flex-direction: column;
     gap: 0;
     &.-reverse{
      flex-direction: column;
    }
    }
	}

	.img-merit-01 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.img-merit-0 {
		flex-shrink: 0;
		width: 620px;
		height: 380px;
		position: relative;
		object-fit: cover;
    @media (max-width: 1199px) {
      width: 100%;
      height: auto;
    }
	}

	.description-merit {
		display: flex;
		flex-direction: column;
		gap: 28px;
		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;
		position: relative;
    @media (max-width: 1199px) {
     margin-top: -16px;
     gap: 16px;
    }
	}

	.ttl {
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.ttl-en {
		background: var(--color-keyred, #d10000);
		padding: 0px 4px 0px 10px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		width: 150px;
		height: 44px;
		position: relative;
	}

	.merit {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: Montserrat, sans-serif;
		font-size: 20px;
		line-height: 100%;
		font-weight: 600;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
    @media (max-width: 1199px) {
   font-size: 18px;
     }
	}

	._1 {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: Montserrat, sans-serif;
		font-size: 36px;
		line-height: 100%;
		font-weight: 600;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.div {
		color: var(--color-black, #1f1f1f);
		text-align: left;
		font-family: 'ヒラギノ明朝 Pro','Hiragino Mincho Pro',serif;
		font-size: 24px;
		line-height: 150%;
		font-weight: 600;
		position: relative;
		align-self: stretch;
		display: flex;
		align-items: center;
		justify-content: flex-start;
    @media (max-width: 1199px) {
      font-size: 20px;
        }
	}

	.div2 {
		color: var(--color-black, #1f1f1f);
		text-align: left;
		font-size: var(--textbase15px-font-size, 15px);
		line-height: var(--textbase15px-line-height, 200%);
		font-weight: var(--textbase15px-font-weight, 400);
		position: relative;
		align-self: stretch;
	}

}
