@use 'css/abstracts' as *;
.sct-flow {
	position: relative;
	overflow: hidden;
	background: #f1f1f1;

	.sct_inner {
		padding: 80px 24px;
		@media (min-width: 769px) {
			padding-top: 120px;
		}
	}
}
.o-flow_wrap {
	margin-top: 56px;
	@media (min-width: 769px) {
		max-width: 1120px;
		margin: auto;
		margin-top: 80px;
		display: flex;
		justify-content: center;
	}
}
.m-flow_item {
	position: relative;
	& + .m-flow_item {
		margin-top: 72px;
	}
	&:nth-child(2) .m-flow_item_step {
		background: #b9a87b;
	}
	&:nth-child(3) .m-flow_item_step {
		background: #7a7159;
	}
	&:not(:last-child)::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: -48px;
		margin: auto;
		display: block;
		width: 24px;
		height: 24px;
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url(../img/top/icon_arrow2.svg);
		transform: rotate(90deg);
	}
	picture {
		display: block;
		max-width: 216px;
		margin: auto;
		margin-top: 40px;
		border-radius: 50%;
		overflow: hidden;
	}
	h3 {
		display: block;
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 140%;
		text-align: center;
		margin-top: 40px;
	}
	p {
		font-family: 'YuGothic';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.8;
		margin-top: 24px;
		text-align: center;
	}
	@media (min-width: 769px) {
		flex: 1 1 calc(33.33% - 80px * 2 / 3);
		& + .m-flow_item {
			margin-top: 0;
			margin-left: 80px;
		}
		&:not(:last-child)::after {
			transform: none;
			width: 24px;
			top: 0;
			bottom: 0;
			right: -52px;
			left: auto;
		}
	}
}
.m-flow_item_step {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	background: #c9ad5f;
	padding: 4px;
	span:nth-child(1) {
		display: inline-block;
		padding-bottom: 4px;
		font-family: 'Albert Sans', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 100%;
		color: #ffffff;
	}
	span:nth-child(2) {
		display: inline-block;
		margin-left: 8px;
		font-family: 'Albert Sans', sans-serif;
		font-style: normal;
		font-weight: 400;
		font-size: 32px;
		line-height: 100%;
		color: #ffffff;
	}
}
