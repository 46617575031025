@use 'css/abstracts' as *;
.sct-cv {
  padding: 22px;
	padding-top: 48px;
	padding-bottom: 56px;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(../img/common/bg_conversion_pc.jpg);
	@media (max-width: 1199px) {
		background-image: url(../img/common/bg_conversion.jpg);
	}
	.inr {
		display: flex;
		flex-direction: column;
		gap: 32px;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		flex-shrink: 0;
		max-width: 920px;
		margin: auto;
		position: relative;
	}

	.div {
		color: var(--color-white, #ffffff);
		text-align: center;
		position: relative;
		align-self: stretch;
	}

	.div-span {
		color: var(--color-white, #ffffff);
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 30px;
		line-height: 160%;
		font-weight: 600;
    @media (max-width: 1199px) {
      font-size: 20px;
    }
	}

	.div-span2 {
		color: var(--color-white, #ffffff);
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 24px;
		line-height: 160%;
		font-weight: 600;
    @media (max-width: 1199px) {
      font-size: 20px;
    }
	}

	.div-span3 {
		color: var(--color-white, #ffffff);
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 30px;
		line-height: 160%;
		font-weight: 600;
    @media (max-width: 1199px) {
      font-size: 20px;
    }
	}

	.div-span4 {
		color: var(--color-white, #ffffff);
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 24px;
		line-height: 160%;
		font-weight: 600;
    @media (max-width: 1199px) {
      font-size: 20px;
    }
	}

	.div-span5 {
		color: var(--color-white, #ffffff);
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 30px;
		line-height: 160%;
		font-weight: 600;
    @media (max-width: 1199px) {
      font-size: 20px;
    }
	}

	.div-span6 {
		color: var(--color-white, #ffffff);
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 24px;
		line-height: 160%;
		font-weight: 600;
    @media (max-width: 1199px) {
      font-size: 20px;
    }
	}

	.div-span7 {
		color: var(--color-white, #ffffff);
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 30px;
		line-height: 160%;
		font-weight: 600;
    @media (max-width: 1199px) {
      font-size: 20px;
    }
	}

	.btan-base {
		background: var(--color-keygold, #a59450);
		padding: 3px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
    width: 100%;
		max-width: 480px;
    margin: auto;
		height: 80px;
		position: relative;
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
    &:hover{
      opacity: .6;
    }
	}

	.inr2 {
		border-style: solid;
		border-color: var(--color-keygoldlight, #eeebe0);
		border-width: 1px;
		display: flex;
		flex-direction: row;
		gap: 16px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex: 1;
		position: relative;
		overflow: hidden;
    padding: 0 20px;
	}

	.medal {
		background: var(--color-white, #ffffff);
		border-radius: 27px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		width: 54px;
		height: 54px;
		position: relative;
	}

	.div2 {
		color: #222222;
		text-align: left;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 19px;
		line-height: 100%;
		font-weight: 600;
		position: relative;
	}

	.lavel {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: center;
		// flex-shrink: 0;
		position: relative;
	}

	.div3 {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 22px;
		line-height: 140%;
		font-weight: 600;
		position: relative;
	}

	.icon-arrow {
		flex-shrink: 0;
		width: 16px;
		height: 16px;
		position: relative;
		overflow: visible;
	}
}
