@use 'css/abstracts' as *;
.sct-about {
	position: relative;
	overflow: hidden;

	.sct_inner {
		background-repeat: no-repeat;
		background-size: cover;
		background-image: url(../img/top/about/bg_img_btm.jpg);
		position: relative;
		padding: 64px 22px;
		text-align: center;
		@media (min-width: 641px) {
			padding: 64px 38px;
		}
		@media (min-width: 1200px) {
			padding: 110px 48px;
			background-image: url(../img/top/about/bg_about_pc.jpg);
		}
	}
	h2 {
		color: var(--color-white, #fff);
		text-align: center;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 30px;
		font-style: normal;
		font-weight: 600;
		line-height: 160%; /* 48px */

		@media (max-width: 1199px) {
			font-size: 22px;
			line-height: 1.7;
		}
	}
	.sub {
		display: inline-block;
		color: var(--color-key_gold, #a59450);
		text-align: center;
		font-size: 17px;
		font-style: normal;
		font-weight: 600;
		line-height: 160%; /* 27.2px */
		border: 1px solid var(--color-key_gold, #a59450);
		padding: 8px 16px;
		margin-top: 32px;
		@media (max-width: 1199px) {
			margin-top: 24px;
			display: block;
		}
	}
	.block,
	.block * {
		box-sizing: border-box;
	}

	.block {
		margin: auto;
		margin-top: 64px;
		max-width: 920px;
		display: flex;
		flex-direction: column;
		gap: 56px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		@media (max-width: 1199px) {
			margin-top: 48px;
      gap: 40px;
		}
	}

	.about {
		background: var(--color-white, #ffffff);
		padding: 4px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
	}

	.inr {
		background: var(--color-white, #ffffff);
		border-style: solid;
		border-color: var(--color-keygold, #a59450);
		border-width: 1px;
		padding: 44px 40px 38px 40px;
		display: flex;
		flex-direction: column;
		gap: 32px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		@media (max-width: 1199px) {
			padding: 26px 20px;
			gap: 24px;
		}
	}

	.img-list {
		display: flex;
		flex-direction: row;
		gap: 32px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		@media (max-width: 1199px) {
			flex-direction: column;
			gap: 16px;
			justify-content: center;
			max-width: 284px;
			margin: auto;
		}
	}

	.img-about-01 {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex: 1;
		// height: 270px;
		position: relative;
    max-width: 480px;
    margin: auto;
	}

	.img-about-02 {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex: 1;
		position: relative;
	}

	.frame-395 {
		display: flex;
		flex-direction: row;
		gap: 32px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.frame-393 {
		padding: 0px 40px 0px 40px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;
		position: relative;
    @media (max-width: 1199px) {
      padding: 0;
    }
	}

	.u-i {
		color: var(--color-black, #1f1f1f);
		text-align: left;
		font-size: var(--textcard14px18-font-size, 14px);
		line-height: var(--textcard14px18-line-height, 180%);
		font-weight: var(--textcard14px18-font-weight, 400);
		position: relative;
		align-self: stretch;
	}

	.about-info {
		background: var(--color-white, #ffffff);
		padding: 4px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
	}

	.inr2 {
		background: var(--color-white, #ffffff);
		border-style: solid;
		border-color: var(--color-keygold, #a59450);
		border-width: 1px;
		padding: 38px 40px 44px 40px;
		display: flex;
		flex-direction: column;
		gap: 32px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		@media (max-width: 1199px) {
			padding: 26px 20px;
			gap: 24px;
		}
	}

	.div {
		color: var(--color-black, #1f1f1f);
		text-align: center;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 24px;
		line-height: 150%;
		font-weight: 600;
		position: relative;
		align-self: stretch;
	}

	.list {
		border-style: solid;
		border-color: var(--color-graycccccc, #cccccc);
		border-width: 1px 0px 0px 0px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.dl-company {
		border-style: solid;
		border-color: var(--color-graycccccc, #cccccc);
		border-width: 0px 0px 1px 0px;
		display: flex;
		flex-direction: row;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

@media (max-width: 1199px) {
  flex-direction: column;
}

	}

	.dt {
		background: rgba(238, 235, 224, 0.5);
		padding: 6px 18px 6px 18px;
		display: flex;
		flex-direction: row;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		width: 170px;
		min-height: 56px;
		position: relative;
    @media (max-width: 1199px) {
      width: 100%;
      padding: 8px 12px 8px 12px;
      min-height: 36px;
      text-align: left;
      justify-content: flex-start;
    }
	}

	.div2 {
		color: var(--color-black, #1f1f1f);
		text-align: center;
		font-size: 16px;
		line-height: 140%;
		font-weight: 600;
		position: relative;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
    @media (max-width: 1199px) {
      font-size: 15px;
      text-align: left;
      justify-content: flex-start;
    }
	}

	.dd {
		padding: 6px 18px 6px 18px;
		display: flex;
		flex-direction: row;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		min-height: 56px;
		position: relative;
    @media (max-width: 1199px) {
      padding: 16px 12px;
      align-items: flex-start;
      width: 100%;
    }
	}

	.div3 {
		color: var(--color-black, #1f1f1f);
		text-align: left;
		font-size: 16px;
		line-height: 140%;
		font-weight: 300;
		position: relative;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.div4 {
		color: var(--color-black, #1f1f1f);
		text-align: center;
		position: relative;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.div-4-span {
		color: var(--color-black, #1f1f1f);
		font-size: 16px;
		line-height: 140%;
		font-weight: 600;
	}

	.div-4-span2 {
		color: var(--color-black, #1f1f1f);
		font-size: 13px;
		line-height: 140%;
		font-weight: 300;
	}
}
