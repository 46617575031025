@use 'css/abstracts' as *;
.sct-job_information {
	position: relative;
	overflow: hidden;

	.sct_inner {
		background: #fff;
		position: relative;
		padding: 64px 22px;
		@media (min-width: 641px) {
			padding: 64px 38px;
		}
		@media (min-width: 1200px) {
			padding: 110px 48px;
		}
	}
	.sct_inner_bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
	.sec-description {
		position: relative;
		color: var(--color-black, #1f1f1f);
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 200%; /* 32px */
		text-align: left;
		margin-top: 24px;
		@media (min-width: 1200px) {
			text-align: center;
			margin-top: 32px;
		}
	}
	.income,
	.income * {
		box-sizing: border-box;
	}

	.income {
		background: var(--color-keygold, #a59450);
		border-style: solid;
		border-color: var(--color-keygold, #a59450);
		border-width: 1px;
		padding: 0px 20px 0px 20px;
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		width: 100%;
		height: 36px;
		position: relative;
	}

	.icon-income {
		background: var(--color-white, #ffffff);
		padding: 1px 0px 0px 0px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		width: 46px;
		height: 20px;
		position: relative;
	}

	.div {
		color: var(--color-black, #1f1f1f);
		text-align: left;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-weight: 600;
		font-size: 13px;
		line-height: 120%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.num {
		color: var(--color-white, #ffffff);
		text-align: center;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.num-span {
		color: var(--color-white, #ffffff);
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-weight: 600;
		font-size: 20px;
		line-height: 120%;
	}

	.num-span2 {
		color: var(--color-white, #ffffff);
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-weight: 600;
		font-size: 14px;
		line-height: 120%;
	}

	.num-span3 {
		color: var(--color-white, #ffffff);
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-weight: 600;
		font-size: 20px;
		line-height: 120%;
	}

	.num-span4 {
		color: var(--color-white, #ffffff);
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-weight: 600;
		font-size: 14px;
		line-height: 120%;
	}
}

.o-job_information_lists {
	position: relative;
	margin-top: 48px;
	left: calc(50% + 22px);
	margin-left: -22px;
	margin-right: -22px;
	transform: translateX(-50%);
	overflow: hidden;
	@media (min-width: 641px) {
		left: calc(50% + 38px);
		margin-left: -38px;
		margin-right: -38px;
	}
	// .swiper{
	//   overflow: visible;
	// }
	.swiper-wrapper {
		margin: auto;
		// max-width: 296px;
	}
	.note {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.8;
		text-align: right;
		margin-top: 24px;
		padding-right: 24px;
	}
	@media (min-width: 1200px) {
		margin: auto;
		margin-top: 64px;
		// display: flex;
		max-width: 1200px;
		left: 0;
		transform: none;

		.note {
			margin-top: 32px;
			padding-right: 0;
		}
	}
}
.m-job_information_list {
	.slide {
		max-width: 300px;
		height: 100%;
		margin: auto;
		border-bottom: 4px solid var(--color-key_red, #d10000);
		background: var(--color-white, #fff);
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
	}
	@media (min-width: 1200px) {
		flex: 1 1 calc(25% - 32px * 3 / 4);
		& + .m-job_information_list {
			margin-left: 32px;
		}
	}
}
.list_inner {
	background: #fff;
	h3 {
		color: var(--color-key_red, #d10000);
		text-align: center;
		font-family: 'ヒラギノ明朝 Pro', 'Hiragino Mincho Pro', serif;
		font-size: 19px;
		font-style: normal;
		font-weight: 600;
		line-height: 140%; /* 26.6px */
		height: 72px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.job_name {
	display: block;
	padding: 10px;
	font-weight: 700;
	font-size: 18px;
	line-height: 140%;
	text-align: center;
	color: #ffffff;
	background: linear-gradient(
		93.92deg,
		#786432 -12.67%,
		#9a8449 27.55%,
		#88784e 106.2%
	);
}
.job_text {
	padding: 24px;
	h4 {
		display: block;
		font-weight: 700;
		font-size: 14px;
		line-height: 1.8;
		color: var(--color-black, #1f1f1f);
		text-align: left;
	}
	p {
		font-family: YuGothic, 'Yu Gothic', sans-serif;
		font-weight: 500;
		font-size: 14px;
		line-height: 1.8;
		margin-top: 8px;
		text-align: left;
	}
}
.incom_tag {
	display: inline-block;
	font-weight: 700;
	font-size: 18px;
	line-height: 100%;
	text-align: center;
	color: #ffffff;
	padding: 4px 12px;
	background: #7a7159;
	margin-top: 16px;
}
.incom_num {
	font-weight: 700;
	font-size: 30px;
	line-height: 1.5;
	.-font-s {
		font-size: 18px;
	}
}

/* スライダー非活性時 */
.swiper:not(.swiper-initialized) {
	padding: 0;
	& ~ .swiper-button-prev,
	& ~ .swiper-button-next {
		display: none;
	}
	.swiper-wrapper {
		display: block;
	}
	.swiper-pagination-bullet {
		display: none;
	}
}
.js-slider {
	.swiper:not(.swiper-initialized) {
		overflow: initial;
		.swiper-wrapper {
			display: flex;
		}
		.swiper-slide {
			height: auto;
		}
	}
}
.swiper-pagination-bullets.swiper-pagination-horizontal {
	bottom: 15px;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
	opacity: 0;
}
.swiper-button-next,
.swiper-button-prev {
	width: 40px;
	top: 210px;
}
.swiper-button-next {
	right: -28px;
}
.swiper-button-prev {
	left: -28px;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
	content: '';
	width: 40px;
	height: 40px;
	background-color: #d10000;
	background-repeat: no-repeat;
	background-size: 16px;
	background-position: center;
	background-image: url(../img/top/job/prev.svg);
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
	content: '';
	width: 40px;
	height: 40px;
	background-color: #d10000;
	background-repeat: no-repeat;
	background-size: 16px;
	background-position: center;
	background-image: url(../img/top/job/prev.svg);
}
// .swiper-pagination-horizontal.swiper-pagination-bullets {
//   .swiper-pagination-bullet{
//     width: 18px;
//     height: 18px;
//     margin: 0 11px;
//     background: $wht;
//     opacity: 1;
//   }
//   .swiper-pagination-bullet-active{
//     background: $ylw;
//   }
// }
@media (max-width: 1199px) {
	.swiper {
		overflow: visible; // 1.はみ出させるように visible で上書き
		//        padding-left: calc(26 / 375 * 100%);
		// padding-right: calc(26 / 375 * 100%);
	}
	.swiper-slide {
		// width: 298px;
		// margin-bottom: 62px;
		height: auto; // 100%だと高さがインラインに
		max-width: 300px;
	}
	.js-slider {
		overflow: hidden; // 2.代わりに親要素で hidden にする
		.slide {
			height: 100%;
			display: flex;
			flex-direction: column;
		}
	}
}
.swiper-button_wrap {
	margin: auto;
	width: 100%;
	height: 100%;
	max-width: 300px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
  @media (min-width: 1200px) {
    display: none;
  }
}
