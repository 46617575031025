@use 'css/abstracts' as *;
.sct-qa {
	position: relative;
	overflow: hidden;

	.sct_inner {
		background: #fff;
		position: relative;
		padding: 64px 22px;
		@media (min-width: 641px) {
			padding: 64px 38px;
		}
		@media (min-width: 1200px) {
			padding: 110px 48px;
		}
	}
	.block {
		max-width: 920px;
		margin: auto;
		margin-top: 64px;
		@media (max-width: 1199px) {
			margin-top: 48px;
		}
	}
	.sec-description {
		color: var(--color-black, #1f1f1f);
		font-size: 16px;
		font-style: normal;
		font-weight: 300;
		line-height: 200%; /* 32px */
		text-align: left;
		margin-top: 32px;
		@media (min-width: 1200px) {
			text-align: center;
		}
	}
	.block,
	.block * {
		box-sizing: border-box;
	}

	.block {
		display: flex;
		flex-direction: row;
		gap: 40px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		@media (max-width: 1199px) {
			flex-direction: column;
			align-items: center;
		}
	}

	.li-card-faq {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		width: 100%;
		max-width: 440px;
		position: relative;
	}

	.img-faq-shimane {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
	}

	.ogp-1 {
		flex-shrink: 0;
		width: 100%;
		max-width: 440px;
		position: relative;
		object-fit: cover;
	}

	.header-logo-2-x-1 {
		flex-shrink: 0;
		width: 131px;
		height: 24px;
		position: absolute;
		left: 8px;
		top: 9px;
		object-fit: cover;
	}

	.btan-base {
		background: var(--color-keyred, #d10000);
		padding: 2px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		width: 260px;
		height: 54px;
		position: relative;
		box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
		&:hover {
			opacity: 0.6;
		}
	}

	.inr {
		border-style: solid;
		border-color: var(--color-keygoldlight, #eeebe0);
		border-width: 1px;
		display: flex;
		flex-direction: row;
		gap: 16px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex: 1;
		position: relative;
		overflow: hidden;
	}

	.lavel {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
	}

	.div {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-size: 16px;
		line-height: 140%;
		font-weight: 600;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
    gap: 2px;
    flex-wrap: wrap;
    flex-direction: column;
		.-sub {
			font-feature-settings: 'palt' on;
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: 120%; /* 14.4px */
			letter-spacing: 0.48px;
		}
	}

	.icon-arrow-blue-svg {
		flex-shrink: 0;
		width: 12px;
		height: 12px;
		position: relative;
		overflow: visible;
	}

	.img-faq-tottori {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
	}

	.kv-01-1 {
		flex-shrink: 0;
		width: 100%;
		max-width: 440px;
		position: relative;
		object-fit: cover;
	}

	.image-16 {
		flex-shrink: 0;
		width: 183px;
		height: 28px;
		position: absolute;
		left: 10px;
		top: 7px;
		object-fit: cover;
	}

	.icon-arrow-blue-svg2 {
		flex-shrink: 0;
		width: 12px;
		height: 12px;
		position: relative;
		overflow: visible;
	}
}
