@use 'css/abstracts' as *;
.sct-intro {
	position: relative;
	background: #dfddd6;
  padding: 0 38px 18px;
  @media (min-width: 1200px) {
    padding-bottom: 14px;
  }

.block {
  max-width: 954px;
  margin: auto;
  transform: translateY(-50px);
    background: var(--color-white, #ffffff);
    padding: 3px;
    display: flex;
    flex-direction: row;
    gap: 95px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.15);
    @media (max-width: 1199px) {
      transform: translateY(-38px);
    }
}

.inr {
    border-style: solid;
    border-color: var(--color-keygold, #a59450);
    border-width: 1px;
    padding: 32px 40px 32px 40px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    justify-content: center;
    flex: 1;
    position: relative;
    @media (max-width: 1199px) {
      flex-direction: column;
      gap: 24px;
      padding: 28px 18px 20px 18px;
    }
}

.logo-gogin-careerdesign {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    max-width: 240px;
    @media (max-width: 1199px) {
      max-width: 288px;
    }
}

.symbol-gogin {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    position: relative;
    overflow: visible;
}

.image-17 {
    flex-shrink: 0;
    width: 240px;
    height: 20px;
    position: relative;
    object-fit: cover;
}

.div-p-intro-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    position: relative;
}

.ttl {
    border-style: solid;
    border-color: var(--color-black, #1f1f1f);
    border-width: 0px 0px 1px 0px;
    padding: 0px 4px 6px 0px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.div {
    text-align: left;
    font-family: 'ヒラギノ明朝 Pro','Hiragino Mincho Pro',serif;
    font-size: 24px;
    line-height: 140%;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.div-span {
    color: var(--color-keyred, #d10000);
    font-family: 'ヒラギノ明朝 Pro','Hiragino Mincho Pro',serif;
    font-size: 24px;
    line-height: 140%;
    font-weight: 600;
}

.div-span2 {
    color: var(--color-black, #1f1f1f);
    font-family: 'ヒラギノ明朝 Pro','Hiragino Mincho Pro',serif;
    font-size: 24px;
    line-height: 140%;
    font-weight: 600;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.li {
    padding: 0px 0px 0px 18px;
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.div2 {
    color: var(--color-black, #1f1f1f);
    text-align: left;
    font-family: 'HiraginoKakuGothicPro-W3', sans-serif;
    font-size: 17px;
    line-height: 160%;
    font-weight: 400;
    position: relative;
    flex: 1;
}

.li-point {
    background: var(--color-keygold, #a59450);
    border-radius: 8px;
    flex-shrink: 0;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0px;
    top: 8.5px;
}
}
