@use 'css/abstracts' as *;
.sct-data {
	position: relative;
	overflow: hidden;

	.sct_inner {
    background: #fff;
		position: relative;
		padding: 64px 22px;
    @media (min-width: 641px) {
			padding: 64px 38px;
		}
		@media (min-width: 1200px) {
      padding: 110px 48px;
		}
	}
.section_content{
  max-width: 976px;
  margin: auto;
  margin-top: 64px;
}
.list-data {
    display: flex;
    flex-direction: row;
    gap: 56px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    @media (max-width: 1199px) {
     flex-direction: column;
     gap: 40px;
     justify-content: center;
     align-items: center;
    }
}

.li-card-data {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 288px;
    position: relative;
    @media (max-width: 1199px) {
      width: 100%;
     }
}

.img-data-graph {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 288px;
    height: 288px;
    position: relative;
}

.img-data-graph-01 {
    flex-shrink: 0;
    width: 288px;
    height: 288px;
    position: relative;
    overflow: visible;
}

.description {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}

.ttl {
    background: var(--color-keygold, #a59450);
    padding: 0px 16px 2px 16px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 180px;
    height: 32px;
    position: relative;
}

.div {
    color: var(--color-white, #ffffff);
    text-align: center;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

._70-40-50-40-60 {
    color: var(--color-black, #1f1f1f);
    text-align: left;
    font-size: 16px;
    line-height: 180%;
    font-weight: 300;
    position: relative;
    align-self: stretch;
}

.img-data-graph-02 {
    flex-shrink: 0;
    width: 288px;
    height: 288px;
    position: relative;
    overflow: visible;
}

.img-data-graph-03 {
    flex-shrink: 0;
    width: 288px;
    height: 288px;
    position: relative;
    overflow: visible;
    @media (max-width: 1199px) {
      width: 300px;
      height: 300px;
    }
}
}
